import React, { Component } from "react"
import { Link } from 'gatsby'
import axios from 'axios';
import { GlobalSection, GlobalContainer, Button } from "../components/GlobalStyle"
import SEO from "../components/seo"
import styled from "styled-components"
import PageHeader from "../components/PageHeader"
import headerImage from "../images/slider/slide-2.jpg"
import Map from "../components/Map"
import { withTranslation } from 'react-i18next';

const StyledFormWrap = styled.div`
  padding: 20px;
  max-width: 1040px;
  margin: 0 auto;
  @media (max-width: 500px) {
    padding: 0;
  }
  form {
    display: flex;
    flex-direction: column;
  }
  input,textarea {
    width: 100%; 
    display: block;
    color: rgba(0, 0, 0, 0.8);
    padding: 17px 26px;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    font-weight: 400;
    transition: all 0.3s ease;
    font: 14px 'Mukta',sans-serif;
  }
`
const StyledFormRow = styled.div`
  display: flex;
  text-align: center;
  div {
    flex: 1;
    margin: 20px 10px;
  }
  @media (max-width: 600px) {
    flex-direction: column;
    div {
      margin: 10px;
    }
  }
`
const StyledFormCheckbox = styled.div`
  margin: 20px 0;
  * {
    font-weight: 400;
    font-size: 16px;
  }
  a {
    text-decoration: underline;
    margin-left: 5px;
    color: #000;
    opacity: .6;
  }
  label {
    span {
      display: inline-block;
      height: 32px;
      width: 32px;
      background-color: #fff;
      border: 1px solid rgba(0,0,0,0.3);
      border-radius: 2px;
      margin-right: 10px;
      position: relative;
      z-index: 2;
      top: 10px;
      transition: border-color .3s;
      cursor: pointer;
      &:hover {
        border-color: var(--main);
      }
      &::after {
        content: '';
        display: block;
        position: absolute;
        height: 6px;
        width: 12px;
        border-left: 3px solid;
        border-bottom: 3px solid;
        border-color: rgba(0,0,0,0.2);
        top: calc(50% - 1px);
        left: 50%;
        transform: translate(-50%, -50%) rotate(-45deg);
        transition: border-color .3s;
      }
    }
    input {
      display: inline-block;
      height: 20px;
      width: 20px;
      z-index:2;
      opacity: 0;
      position: absolute;
    }
    input:checked ~ span {
      border-color: var(--main);
    }
    input:checked ~ span:after {
      border-color: var(--main);
    }
  }
`
const StyledFormButton = styled.button`
  border-radius: 32px;
  font-weight: 700;
  font-size: 18px;
  padding: 18px 56px;
  min-width: 245px;
  position: relative;
  overflow: hidden;
  display: inline-block;
  text-align: center;
  border: 0;
  background-clip: border-box !important;
  transition: background 0.3s, color 0.3s !important;
  color: var(--black);
  transform: scale(1);
  background-color: var(--main);
  &:hover {
    background-color: #008032;
    color: #fff;
  }
`
const StyledMessageBox = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0,0,0,0.5);
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  transition: .3s;
  div {
    background-color: #fff;
    border-radius: 2px;
    padding: 40px;
    text-align: center;
    box-shadow: 0 0 20px 20px rgba(0,0,0,0.05);
    }
    p {
      margin: 20px 0;
    }
    span {
      font-weight: bold;
      font-size: 2em;
      color: var(--main);
    }
  }
`

class ContactPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      email: '',
      subject: '',
      message: '',
      messageBox: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }
  handleChange(event) {
    const inputValue = event.target.value;
    const stateField = event.target.name;
    this.setState({
      [stateField]: inputValue,
    });
  }
  async handleSubmit(event) {
    event.preventDefault();
    const { name, message, subject, email } = this.state;
    await axios.post(
      'https://i9nrlkmjhj.execute-api.eu-west-1.amazonaws.com/beta/contact',
      { name, message, subject, email }
    ).then(
      this.setState({
        messageBox: true,
      }
    ));
  }

  render() {
    return (
      <>
        <SEO
          title="Kontakt"
          description="Skontaktuj się z nami aby poznać więcej szczegółów o naszym coworkingu"
        />
        <PageHeader image={headerImage}>
          <h1 data-sal="slide-up" data-sal-delay="400" data-sal-duration="400">{this.props.t('header.contactTitle')}</h1>
        </PageHeader>
        <GlobalSection bg="#F4F4F4">
          <GlobalContainer>
            <StyledFormWrap>
              <form id="contactForm" name="contact" onSubmit={this.handleSubmit}>
                <StyledFormRow>
                  <div>
                    <input
                      type="text"
                      name="name"
                      id="name"
                      onChange={this.handleChange}
                      value={this.state.name}
                      placeholder={this.props.t('contact.name')}
                      required/>
                  </div>
                  <div>
                    <input
                      type="email"
                      name="email"
                      id="email"
                      onChange={this.handleChange}
                      value={this.state.email}
											placeholder={this.props.t('contact.email')}
											required/>
                  </div>
                </StyledFormRow>
                <StyledFormRow>
                  <div>
                    <input
                      type="text"
                      name="subject"
                      id="subject"
                      onChange={this.handleChange}
                      value={this.state.subject}
											placeholder={this.props.t('contact.topic')}
											required/>
                  </div>
                </StyledFormRow>
                <StyledFormRow>
                  <div>
                    <textarea
                      name="message"
											placeholder={this.props.t('contact.message')}
                      id="message"
                      onChange={this.handleChange}
                      value={this.state.message}
                      rows="10" 
											required/>
                  </div>
                </StyledFormRow>
                <StyledFormRow>
                  <StyledFormCheckbox>
                    <label>
                      <input type="checkbox" required/>
                      <span></span>
											{this.props.t('contact.checkbox1')}
                      <Link to="/polityka-prywatnosci" target="_blank">{this.props.t('contact.checkbox2')}</Link>
                    </label>
                  </StyledFormCheckbox>
                </StyledFormRow>
                <StyledFormRow center>
                  <div>
                    <StyledFormButton type="submit">{this.props.t('common.send')}</StyledFormButton>
                  </div>
                </StyledFormRow>
              </form>
            </StyledFormWrap>
            {
              this.state.messageBox
              &&
              <StyledMessageBox >
                <div>
                  <span>Udało się!</span>
                  <p>Dziękujemy za wiadomość,
                    <br/>
                    skontaktujemy się z Tobą tak szybko jak to możliwe :)
                  </p>
                  <Button to="/">Powrót</Button>
                </div>
              </StyledMessageBox>
            }
          </GlobalContainer>
        </GlobalSection>
        <Map/>
      </>
    )
  }
}
export default withTranslation()(ContactPage)
